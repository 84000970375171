import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../../Components/Auth/FirebaseConfig';
import { doc, setDoc, deleteDoc, getDocs, collection } from 'firebase/firestore';
import Card from './Card';
import PlaceholderCard from './PlaceholderCard';

const CardList = ({ products, loading }) => {
  const [likedProducts, setLikedProducts] = useState({});

  useEffect(() => {
    const fetchLikedProducts = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const favoritesCollection = collection(firestore, 'users', userId, 'Favorites');
        const snapshot = await getDocs(favoritesCollection);
        
        const liked = {};
        snapshot.forEach((doc) => {
          liked[doc.id] = true;  // Mark product as liked
        });
        setLikedProducts(liked);
      }
    };

    fetchLikedProducts();
  }, []);

  const handleToggleLike = async (id, product, event) => {
    event.stopPropagation();
    const user = auth.currentUser;

    if (user) {
      setLikedProducts((prevLiked) => ({ ...prevLiked, [id]: !prevLiked[id] }));

      const userId = user.uid;
      const productRef = doc(firestore, 'users', userId, 'Favorites', id);

      try {
        if (likedProducts[id]) {
          await deleteDoc(productRef);
        } else {
          await setDoc(productRef, product);
        }
      } catch (error) {
        console.error("Error adding/removing product to/from favorites:", error);
        setLikedProducts((prevLiked) => ({ ...prevLiked, [id]: !prevLiked[id] }));
      }
    }
  };

  return (
    <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-6">
        {loading
          ? Array(8).fill(null).map((_, index) => <PlaceholderCard key={index} />)
          : products.map((product) => (
              <Card
                key={product.id}
                product={product}
                liked={likedProducts[product.id]}
                handleToggleLike={(e) => handleToggleLike(product.id, product, e)}
              />
          ))}
      </div>
    </div>
  );
};

export default CardList;
