import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { FaGoogle } from 'react-icons/fa';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, firestore } from '../Auth/FirebaseConfig';
import {setDoc, doc} from 'firebase/firestore'

export default function SignUp() {

  const navigate = useNavigate(); // Use navigate hook



  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Save user information to Firestore
      await setDoc(doc(firestore, 'users', user.uid), {
        name: user.displayName,
        email: user.email,
        contact: '', // Google Sign-In does not provide phone numbers
        photoURL: user.photoURL,
      });

      alert('Signed up with Google successfully. Welcome to Gyasi Build Mart');

      // Navigate to the home screen
      navigate('/'); // Use the path for your home screen
    } catch (error) {
      alert('Error signing up with Google. Please try again.');
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
          GYASI BUILD MART
        </h2>
        <h2 className="mt-3 text-center text-1xl font-bold leading-9 tracking-tight text-gray-900">
          Sign up for an account
        </h2>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">


        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
        </div>

        <div>
          <button
            onClick={handleGoogleSignUp}
            className="flex w-full items-center justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            <FaGoogle className="mr-2" /> Sign up with Google
          </button>
        </div>
      </div>
    </div>
  );
}
