import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { auth, firestore } from '../../Components/Auth/FirebaseConfig';
import { doc, setDoc, deleteDoc, getDocs, collection } from 'firebase/firestore';
import Card from './Card';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const CardCarousel = ({ products, loading }) => {
  const [likedProducts, setLikedProducts] = useState({});

  useEffect(() => {
    const fetchLikedProducts = async () => {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const favoritesCollection = collection(firestore, 'users', userId, 'Favorites');
        const snapshot = await getDocs(favoritesCollection);
        
        const liked = {};
        snapshot.forEach((doc) => {
          liked[doc.id] = true;  // Mark product as liked
        });
        setLikedProducts(liked);
      }
    };

    fetchLikedProducts();
  }, []);

  const handleToggleLike = async (id, product, event) => {
    event.stopPropagation();
    const user = auth.currentUser;

    if (user) {
      setLikedProducts((prevLiked) => ({ ...prevLiked, [id]: !prevLiked[id] }));

      const userId = user.uid;
      const productRef = doc(firestore, 'users', userId, 'Favorites', id);

      try {
        if (likedProducts[id]) {
          await deleteDoc(productRef);
        } else {
          await setDoc(productRef, product);
        }
      } catch (error) {
        console.error("Error adding/removing product to/from favorites:", error);
        setLikedProducts((prevLiked) => ({ ...prevLiked, [id]: !prevLiked[id] }));
      }
    }
  };

  const settings = {
    dots: false,
    infinite: true, // Infinite scroll for continuous experience
    speed: 500, // Control the speed of sliding
    slidesToShow: 4, // Number of slides to show on desktop
    slidesToScroll: 1, // Only one slide scrolls at a time for precise control
    swipeToSlide: true, // Allows smooth swipe by the user
    draggable: true, // Enables user to drag/swipe as they want
    autoplay: true, // Automatically scrolls when idle
    autoplaySpeed: 3000, // Auto scroll every 3 seconds
    pauseOnHover: true, // Pause auto-scroll when the user hovers over a card
    responsive: [
      {
        breakpoint: 1024, // For screens smaller than 1024px (tablets)
        settings: {
          slidesToShow: 2, // Show 2 items on tablets
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600, // For screens smaller than 600px (mobile)
        settings: {
          slidesToShow: 2, // Show 1 item on mobile
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Slider {...settings}>
          {products.map((product) => (
            <div key={product.id} className="carousel-item p-2">
              <Card
                product={product}
                liked={likedProducts[product.id]}
                handleToggleLike={(e) => handleToggleLike(product.id, product, e)}
              />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default CardCarousel;
