// src/pages/Favorite.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cards from '../Reusables/Cards'; // Adjust the import path as needed
import { useFavorites } from '../../Context/FavoriteContext'; // Adjust the import path as needed
import Navbar from '../NavBar/NavBar'; // Adjust the import path as needed
import SearchGif from '../../Assets/Images/Search.gif'; // Adjust the import path as needed
import Empty from "../../Assets/Images/Empty.gif"

const Favorite = () => {
  const { favorites, loading } = useFavorites();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Filter the favorites based on the search term
  const filteredFavorites = favorites.filter(product => 
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
    product.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle search input change
  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  // Navigate to the homepage
  const handleShopNow = () => {
    navigate('/');
  };

  return (
    <div className="bg-white min-h-screen">
      <Navbar onSearch={handleSearch} />
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 py-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Your Favorites</h2>
        {loading ? (
            <Cards products={[]} loading={true} /> 
        ) : favorites.length === 0 ? ( // Check if there are no items in the favorites list
          <div className="text-center mt-10">
            <img 
              src={Empty} 
              alt="No favorites found" 
              className="w-40 h-40 mx-auto"
            />
            <p className="text-xl text-gray-500 mt-4">No item available in your favorite catalogue. Please Add now.</p>
            <button 
              onClick={handleShopNow}
              className="mt-6 bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-800 transition duration-300"
            >
              Shop Now
            </button>
          </div>
        ) : filteredFavorites.length === 0 ? ( // Check if search results do not match anything
          <div className="text-center mt-10">
            <img 
              src={SearchGif} 
              alt="No search found" 
              className="w-40 h-40 mx-auto"
            />
            <p className="text-xl text-gray-500 mt-4">Your search doesn't match anything in your favorite catalogue.</p>
          </div>
        ) : (
          <Cards products={filteredFavorites} loading={loading} />
        )}
      </div>
    </div>
  );
};

export default Favorite;
