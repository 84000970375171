import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, firestore } from '../Components/Auth/FirebaseConfig'; // Import your firebaseConfig
import { doc, onSnapshot } from 'firebase/firestore';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const unsubscribeUser = onSnapshot(userRef, (doc) => {
          setUserData({ id: doc.id, ...doc.data() });
        });
        return () => unsubscribeUser(); // Clean up subscription on unmount
      } else {
        setUserData(null);
      }
    });
    return () => unsubscribe(); // Clean up auth subscription on unmount
  }, []);

  return (
    <UserContext.Provider value={userData}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
