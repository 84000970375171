import React from 'react';
import { Link } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline'; // Import TrashIcon from Heroicons

function CartItem({ item, handleIncrement, handleDecrement, handleDelete }) {
  return (
    <li key={item.id} className="flex py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <img
          alt={item.imageAlt || item.name}
          src={item.imageUrl || 'default_image_url'} // Fallback image
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>
              <Link to={`/details/${item.id}`} className="block h-full">
                <button type="button">{item.name}</button>
              </Link>
            </h3>
            <p className="ml-4">GHc {(item.unitPrice * item.quantity).toFixed(2)}</p>
          </div>
          <p className="mt-1 text-sm text-gray-500">{item.color}</p>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <div className="flex items-center">
            <button
              type="button"
              onClick={() => handleDecrement(item.id)}
              className="p-1 text-gray-500 hover:text-gray-700"
            >
              -
            </button>
            <p className="mx-2 text-gray-500">Qty {item.quantity}</p>
            <button
              type="button"
              onClick={() => handleIncrement(item.id)}
              className="p-1 text-gray-500 hover:text-gray-700"
            >
              +
            </button>
          </div>
          <button
            type="button"
            onClick={() => handleDelete(item.id)}
            className="text-red-600 hover:text-red-700 flex items-center"
          >
            <TrashIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </li>
  );
}

export default CartItem;
