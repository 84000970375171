import React from 'react';

export default function UserEmail({ userEmail }) {
  return (
    userEmail && (
      <div className="mt-4 text-sm text-gray-500">
        Logged in as: <span className="font-medium">{userEmail}</span>
      </div>
    )
  );
}
