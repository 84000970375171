import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

const categories = ['Construction', 'Plumbing', 'Hardware', 'Electricals'];

const categoryImages = {
  Construction: 'https://i.pinimg.com/564x/43/d6/5a/43d65a4030f1e2fd6c11e18fbfb4bcea.jpg',
  Plumbing: 'https://i.pinimg.com/564x/5e/80/de/5e80debec959a06c369fb6cae7b0c395.jpg',
  Hardware: 'https://i.pinimg.com/736x/9c/ef/ad/9cefad73d161a2c5bdbda638a15b8864.jpg',
  Electricals: 'https://i.pinimg.com/564x/be/3d/10/be3d107b9633afd1039f3967247b889b.jpg',
};

const CategoryNavigation = ({ excludeCategory }) => {
  const navigate = useNavigate();

  // Filter out the excluded category (e.g., "Construction")
  const filteredCategories = categories.filter(category => category !== excludeCategory);

  const handleCategoryClick = (category) => {
    navigate(`/category/${category.toLowerCase()}`);
  };

  return (
    <div className="sticky bg-white z-10 flex flex-wrap justify-center gap-6 p-4 top-[110px] sm:top-[110px] md:top-[60px] lg:top-[115px]">
      {filteredCategories.map((category) => (
        <div
          key={category}
          className="flex flex-col items-center cursor-pointer hover:scale-105 transition-transform duration-300"
          onClick={() => handleCategoryClick(category)}
        >
<img
  src={categoryImages[category]} // Use the category-specific image URL
  alt={category}
  className="w-16 h-16 rounded-full object-cover sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-20 lg:h-20"
  width="64"  
  height="64" 
/>
          <div className="mt-2 font-bold text-xs sm:text-sm md:text-base lg:text-lg">{category}</div> {/* Adjust text size */}
        </div>
      ))}
    </div>
  );
};

export default CategoryNavigation;
