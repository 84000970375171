import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Bathroom from "../../../Assets/Images/plumbingCarousel/Bathroom.jpeg";
import Kitchen from "../../../Assets/Images/plumbingCarousel/Kitchen.jpeg";
import BathroomShower from "../../../Assets/Images/plumbingCarousel/BathroomShower.jpeg";
import KitchenSink from "../../../Assets/Images/plumbingCarousel/KitchenSink.jpeg";
import WC from "../../../Assets/Images/plumbingCarousel/WC.jpeg";
import KitchenTap from "../../../Assets/Images/plumbingCarousel/KitchentTap.jpg";


const PlumbingCarousel = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const items = [
    {
      src: Bathroom,
      text: 'Elegant Bathroom Fixtures to Enhance Your Space',
    },
    {
      src: Kitchen,
      text: 'Sophisticated Kitchen Designs for Optimal Functionality',
    },
    {
      src: BathroomShower,
      text: 'Modern Shower Systems for a Luxurious Experience',
    },
    {
      src: KitchenSink,
      text: 'Durable Kitchen Sinks for Every Cooking Need',
    },
    {
      src: WC,
      text: 'High-Quality WC Solutions for Ultimate Comfort',
    },
    {
      src: KitchenTap,
      text: 'Stylish Kitchen Taps for Functional Elegance',
    },
  ];
  

  return (
    <Box
      sx={{
        position: 'relative',
        marginTop: '0', // Adjust this value to match the height of your navbar
        zIndex: 0, // Ensure it sits under any fixed elements like the navbar
      }}
    >
      <Carousel
        indicators={false}
        navButtonsAlwaysVisible={false}
        animation="slide"
        interval={5000}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              height: isMobile ? '350px' : '500px',
              backgroundImage: `url(${item.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Dark Overlay */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay with 50% opacity
                zIndex: 1,
              }}
            />

            {/* Bolded Text */}
            <Typography
              variant={isMobile ? 'h6' : 'h4'}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                position: 'relative',
                zIndex: 2,
                textAlign: 'center',
                padding: '0 10px',
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default PlumbingCarousel;
