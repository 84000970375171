import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

// Import data from the newly created file
import { subcategories, subcategoryImages } from '../../Assets/subcategoriesData';

const SubcategoryCarousel = () => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Speed for the auto-slide transition
    slidesToShow: 10, // Show 10 items on desktop
    slidesToScroll: 1, // Scroll one item at a time for smoothness
    autoplay: true, // Enable auto sliding
    draggable: true,  // Slide every 3 seconds
    swipeToSlide: true, // Allow manual swipe to move between slides
    touchMove: true, // Enable touch gestures for mobile
    autoplay:true,
    autoplaySpeed: 3000,
    pauseOnHover: true, // Pause autoplay when the user interacts
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6, // Adjust number of slides for medium screens
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4, // Show 4 items on mobile
          slidesToScroll: 1, // Scroll one item at a time on mobile
          speed: 800,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  const handleSubcategoryClick = (subcategory) => {
    navigate(`/subcategory/${subcategory.toLowerCase()}`);
  };

  return (
    <div className="py-4">
      <Slider {...settings}>
        {subcategories.map((subcategory) => (
          <div
            key={subcategory}
            className="flex flex-col items-center cursor-pointer hover:scale-105 transition-transform duration-300 px-2"
            onClick={() => handleSubcategoryClick(subcategory)}
          >
            <img
              src={subcategoryImages[subcategory] || 'https://via.placeholder.com/150'}
              alt={subcategory}
              className="w-16 h-16 rounded-full object-cover sm:w-12 sm:h-12 md:w-16 md:h-16 lg:w-24 lg:h-24"
            />
            <div className="mt-2 font-bold text-xs sm:text-sm md:text-base lg:text-lg text-center">
              {subcategory}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SubcategoryCarousel;
