import React, { useState } from 'react';
import { CircularProgress } from '@mui/material'; // Material-UI CircularProgress for loading indicator

function CheckoutForm({ formData, handleChange, handleCheckout, subtotal }) {
  // State to manage loading
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading to true when submitting

    try {
      // Call the handleCheckout function passed as a prop
      await handleCheckout(formData);
    } catch (error) {
      console.error('Checkout failed', error);
    } finally {
      setIsLoading(false); // Set loading to false after completion
    }
  };

  return (
    <div className="p-4 max-w-lg mx-auto bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-bold text-gray-900 text-center">Checkout Form</h2>
      <p className='text-center mb-6'>Please provide accurate information</p>
      <form className="space-y-6" onSubmit={onSubmit}>
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            disabled={isLoading} // Disable input if loading
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 py-3 px-4 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">
            Address <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            disabled={isLoading} // Disable input if loading
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 py-3 px-4 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
            Contact <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="contact"
            name="contact"
            value={formData.contact}
            onChange={handleChange}
            required
            disabled={isLoading} // Disable input if loading
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 py-3 px-4 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="deliveryMethod" className="block text-sm font-medium text-gray-700">
            Delivery Method <span className="text-red-500">*</span>
          </label>
          <select
            id="deliveryMethod"
            name="deliveryMethod"
            value={formData.deliveryMethod}
            onChange={handleChange}
            required
            disabled={isLoading} // Disable input if loading
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 py-3 px-4 sm:text-sm"
          >
            <option value="">Select a delivery method</option>
            <option value="pickup">Pickup</option>
            <option value="delivery">Delivery</option>
          </select>
          {formData.deliveryMethod === 'delivery' && (
            <p className="text-sm text-red-600 mt-2">Kindly be notified that you would cover fully the cost of delivery</p>
          )}
        </div>
        <div>
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
            Notes
          </label>
          <textarea
            id="notes"
            placeholder='Provide Extra Information for Delivery'
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            disabled={isLoading} // Disable textarea if loading
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 py-3 px-4 sm:text-sm"
          />
        </div>
        <p className="text-lg font-bold text-gray-900 pt-5 text-center">Total Amount: GHc {new Intl.NumberFormat().format(subtotal.toFixed(2))}</p>
        <p className='text-center text-sm text-gray-500'>Strictly Payment Before Delivery</p>
        <div>
          <button
            type="submit"
            disabled={isLoading} // Disable button if loading
            className={`w-full py-2 px-4 rounded-md shadow-sm ${isLoading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'} text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center justify-center`}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              `Checkout GHc ${new Intl.NumberFormat().format(subtotal.toFixed(2))}`
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CheckoutForm;
