import React, { useState, useEffect } from 'react';
import Navbar from '../../Components/NavBar/NavBar';
import Cards from "../../Components/Reusables/Cards";
import ImageCarousel from "./ImageCarousel";
import CategoryNavigation from './CategoryNavigation';
import { useProducts } from '../../Context/ProductContext';
import CardCarousel from '../../Components/Reusables/CardCarousel';

const Home = () => {
  const { products, loading, error } = useProducts();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (products.length > 0) {
      setFilteredProducts(products);
    }
  }, [products]);

  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error]);

  const handleFilterChange = (filter) => {
    if (filter === 'All') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product => product.category === filter);
      setFilteredProducts(filtered);
    }
  };

  const handleSearch = (searchTerm) => {
    const searchedProducts = products.filter(product => 
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(searchedProducts);
  };

  const handleCategoryClick = (category) => {
    handleFilterChange(category);
  };

  return (
    <div style={{ width: '100%', margin: '0', padding: '0', boxSizing: 'border-box' }}>
      <Navbar onSearch={handleSearch} />
      <ImageCarousel />
      <CategoryNavigation onCategoryClick={handleCategoryClick} />
      <h2 className="text-2xl font-bold text-center tracking-tight text-gray-900">Our Building Catalogue</h2>
      {loading ? (
        <Cards products={[]} loading={true} /> // Pass an empty array with loading flag
      ) : hasError ? (
        <p>Unable to load products at this time. Please try again later.</p>
      ) : (
        <Cards products={filteredProducts} />
      )}
    </div>
  );
};

export default Home;
