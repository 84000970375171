import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../Components/Auth/FirebaseConfig';
import { collection, onSnapshot } from 'firebase/firestore';

const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);

      if (authUser) {
        const userId = authUser.uid;
        const favoritesRef = collection(firestore, 'users', userId, 'Favorites');

        // Set up real-time listener to the Favorites collection
        const unsubscribeFavorites = onSnapshot(favoritesRef, (snapshot) => {
          const favoritesList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setFavorites(favoritesList);
          setLoading(false);
        }, (error) => {
          console.error("Error listening to favorites:", error);
          setFavorites([]);
          setLoading(false);
        });

        // Clean up the listener when the component unmounts or user changes
        return unsubscribeFavorites;
      } else {
        setFavorites([]);
        setLoading(false);
      }
    });

    // Clean up the auth listener
    return () => unsubscribeAuth();
  }, []);

  return (
    <FavoritesContext.Provider value={{ favorites, loading }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error('useFavorites must be used within a FavoritesProvider');
  }
  return context;
};
