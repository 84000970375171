import React from 'react';
import { IconButton, Drawer, Box, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom'; // Import Link for navigation

// Menu data for main menu, categories, and subcategories
const menuData = [
  { label: "Home", link: "/" },
  { label: "Services & About", link: "/services" },
  // { label: "Contact", link: "/contact" },
];

const categoryData = [
  { label: "Plumbing", link: "/category/plumbing" },
  { label: "Hardware", link: "/category/hardware" },
  { label: "Electricals", link: "/category/electricals" },
  { label: "Construction", link: "/category/construction" },
];

const subcategoryData = [
  { label: "Pipe Fitting", link: "/subcategory/pipe fitting" },
  { label: "Pipes", link: "/subcategory/pipes" },
  { label: "Cement", link: "/subcategory/cement" },
  { label: "Roofing Materials", link: "/subcategory/roofing materials" },
  { label: "Tiles", link: "/subcategory/tiles" },
  { label: "Plywood", link: "/subcategory/plywood" },
  { label: "Paint", link: "/subcategory/paint" },
  { label: "Painting Tools", link: "/subcategory/painting tools" },
  { label: "Window Glass", link: "/subcategory/window glass" },
  { label: "Mesh", link: "/subcategory/mesh" },
  { label: "WC", link: "/subcategory/wc" },
  { label: "Sink", link: "/subcategory/sink" },
  { label: "Taps", link: "/subcategory/taps" },
  { label: "Basin", link: "/subcategory/basin" },
  { label: "Iron Rod", link: "/subcategory/ironrod" },
  { label: "Doors", link: "/subcategory/doors" },
  { label: "Wooden Doors", link: "/subcategory/wooden doors" },
  { label: "PoP Materials", link: "/subcategory/pop materials" },
  { label: "Polishing Liquid", link: "/subcategory/Polishing Liquid" },
  { label: "PVC Pipe", link: "/subcategory/pvc pipe" },
  { label: "Nails", link: "/subcategory/nails" },
  { label: "Waterproof Materials", link: "/subcategory/waterproof materials" },
  { label: "T and G", link: "/subcategory/T and G" },
  { label: "Keys", link: "/subcategory/Keys" },
  { label: "Chandelier", link: "/subcategory/Chandelier" },
  { label: "Single Door", link: "/subcategory/single door" },
  { label: "Fan", link: "/subcategory/Fan" },
  { label: "Sandpaper", link: "/subcategory/Sandpaper" },
  { label: "Padlock", link: "/subcategory/padlocks" },
  { label: "Tower Bolt", link: "/subcategory/Tower Boat" },
  { label: "Hinges", link: "/subcategory/hinges" },
  { label: "Screw", link: "/subcategory/Screw" },
  { label: "Handles", link: "/subcategory/handles" },
  { label: "Hasp and Staple", link: "/subcategory/Hasp and Staple" },
  { label: "Bulb", link: "/subcategory/bulb" },
  { label: "JB", link: "/subcategory/JB" },
  { label: "Lights", link: "/subcategory/Lights" },
  { label: "Main Switch", link: "/subcategory/Main Switch" },
  { label: "PPr Fitting", link: "/subcategory/PPr Fitting" },
  { label: "PPr Pipe", link: "/subcategory/PPr Pipe" },
  { label: "Bathroom Accessories", link: "/subcategory/Bathroom Accessories" },
  { label: "Cable", link: "/subcategory/cable" },
  { label: "3-phase", link: "/subcategory/3-phase" },
  { label: "Flood Light", link: "/subcategory/flood light" },
  { label: "Others", link: "/subcategory/others" },
];

const MenuIconButton = ({ drawerOpen, toggleDrawer }) => {
  return (
    <>
      <IconButton edge="start" color="primary" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ color: '#000000' }} />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          sx={{ width: 250 }}
        >
          <Box sx={{ padding: '1rem' }}>
            <Typography variant="h6" sx={{ marginBottom: 2, color: '#000000' }}>
              GBM
            </Typography>
            <Divider />
            
            {/* Main Menu */}
            <List>
              {menuData.map((item) => (
                <ListItem key={item.label} component={Link} to={item.link}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
            <Divider />
            
            {/* Categories */}
            <List>
              {categoryData.map((item) => (
                <ListItem key={item.label} component={Link} to={item.link}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
            <Divider />
            
            {/* Subcategories */}
            <List>
              {subcategoryData.map((item) => (
                <ListItem key={item.label} component={Link} to={item.link}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MenuIconButton;
