import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <Container maxWidth="lg" className="my-12 px-6">
    <Typography variant="h5" className="text-black font-semibold">
    Welcome to Gyasi Build Mart
  </Typography>
      <div className="text-justify mb-12 mt-8">
        <Typography variant="body1" className="text-black leading-7 pb-5">
          Welcome to <span className="font-semibold">Gyasi Build Mart</span>, your one-stop destination for high-quality building materials! 
          At <span className="font-semibold">Gyasi Build Mart</span>, we are passionate about providing top-notch construction supplies to builders, contractors, 
          and DIY enthusiasts alike. With a commitment to excellence and customer satisfaction, we aim to be your trusted partner in all your building projects.
        </Typography>
      </div>
      
      <div className="text-justify mb-12">
        <Typography variant="h5" className="font-semibold pb-4 text-black">
          Comprehensive Product Range
        </Typography>
        <Typography variant="h6" className="text-black pb-2">
          Wide Selection
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          At Gyasi Build Mart, we pride ourselves on offering an extensive variety of products that cater to every aspect of your construction needs. 
          Whether you're looking for top-quality materials for building, electrical components, advanced lighting solutions, or reliable plumbing supplies, 
          we have you covered. Our vast selection ensures that you can find everything you need under one roof, simplifying your procurement process.
        </Typography>
        <Typography variant="h6" className="text-black mt-6 pb-2">
          Quality Assurance
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          We understand that the quality of materials is crucial to the success of any project. That’s why we are committed to providing only the best products 
          sourced from reputable manufacturers. Every item in our inventory meets or exceeds industry standards, ensuring reliability and durability for your projects.
        </Typography>
      </div>

      <Typography variant="h5" className="font-semibold mb-6 text-black">
      Expert Consultation and Support
    </Typography>
      <div className="text-justify mb-12">
        <Typography variant="h6" className="text-black pb-2">
          Technical Expertise
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          At Gyasi Build Mart, we believe in empowering our customers with knowledge. Our team of experienced professionals is always available to offer expert guidance, 
          helping you select the right products that perfectly align with your project requirements. With years of industry experience, we provide insights and advice that 
          can make a significant difference in the outcome of your work.
        </Typography>
        <Typography variant="h6" className="text-black mt-6 pb-2">
          Customized Solutions
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          Every project is unique, and so are its requirements. We offer customized solutions tailored to meet the specific needs of your construction, electrical, 
          or plumbing projects. From selecting the right materials to advising on the best practices, we ensure that your project receives the personalized attention it deserves.
        </Typography>
      </div>

      <div className="text-justify mb-12">
        <Typography variant="h5" className="font-semibold mb-6 text-black">
          Competitive Pricing
        </Typography>
        <Typography variant="h6" className="text-black pb-2">
          Best Value
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          We understand the importance of cost-effectiveness in construction projects. At Gyasi Build Mart, we offer competitive pricing that guarantees the best value for your money without compromising on quality. 
          Our pricing strategy is designed to make top-quality materials accessible to everyone, from individual homeowners to large-scale contractors.
        </Typography>
        <Typography variant="h6" className="text-black mt-6 pb-2">
          Bulk Discounts
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          For those involved in large projects or bulk purchasing, we offer attractive discounts that further enhance your savings. Our bulk discounts are tailored to meet the needs of contractors and large-scale developers, 
          ensuring that you get the best deal possible.
        </Typography>
      </div>

      <div className="text-justify mb-12">
        <Typography variant="h5" className="font-semibold mb-6 text-black">
          Fast and Reliable Delivery
        </Typography>
        <Typography variant="h6" className="text-black pb-2">
          Timely Deliveries
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          At Gyasi Build Mart, we understand that time is of the essence in any project. Our fast and reliable delivery services ensure that your materials arrive on-site exactly when you need them. 
          We are committed to keeping your projects on schedule with timely and efficient delivery services.
        </Typography>
        <Typography variant="h6" className="text-black mt-6 pb-2">
          Nationwide Coverage
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          Whether your project is in a bustling city or a remote location, we’ve got you covered. Our delivery network spans across the nation, ensuring that no matter where you are, you have access to the materials you need. 
          We take pride in our ability to reach every corner of the country, making sure your project is never delayed due to material shortages.
        </Typography>
      </div>

      <div className="text-justify mb-12">
        <Typography variant="h5" className="font-semibold mb-6 text-black">
          Sustainability Commitment
        </Typography>
        <Typography variant="h6" className="text-black pb-2">
          Eco-Friendly Products
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          Sustainability is at the heart of everything we do at Gyasi Build Mart. We are dedicated to providing eco-friendly products that minimize environmental impact while maintaining the highest standards of quality and performance. 
          Our selection includes a wide range of sustainable materials that contribute to a greener future.
        </Typography>
        <Typography variant="h6" className="text-black mt-6 pb-2">
          Responsible Sourcing
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          We believe in responsible sourcing and are committed to ensuring that all our materials are sourced ethically and sustainably. 
          Our partnerships with suppliers are built on a foundation of trust and a shared commitment to environmental stewardship, 
          ensuring that our products support sustainability goals at every stage of the supply chain.
        </Typography>
      </div>

      <div className="text-justify mb-12">
        <Typography variant="h5" className="font-semibold mb-6 text-black">
          Customer Service Excellence
        </Typography>
        <Typography variant="h6" className="text-black pb-2">
          Personalized Service
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          At Gyasi Build Mart, we take a customer-centric approach in everything we do. Our personalized service ensures that each customer receives the attention and support they need. 
          We take the time to understand your unique requirements and work closely with you to provide solutions that are tailored to your specific needs.
        </Typography>
        <Typography variant="h6" className="text-black mt-6 pb-2">
          After-Sales Support
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          Our commitment to your success doesn’t end with your purchase. We offer comprehensive after-sales support, including installation assistance and ongoing support. 
          Our team is here to help you at every stage of your project, ensuring that you have the resources and expertise you need to achieve your goals.
        </Typography>
      </div>

      <div className="text-justify mb-12">
        <Typography variant="h5" className="font-semibold mb-6 text-black">
          Innovation and Technology
        </Typography>
        <Typography variant="h6" className="text-black pb-2">
          Modern Solutions
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          At Gyasi Build Mart, we are committed to staying at the forefront of industry innovation. We continuously invest in modern solutions and advanced technologies that enhance the quality and performance of our products. 
          From state-of-the-art building materials to cutting-edge electrical components, we bring the latest innovations to your projects.
        </Typography>
        <Typography variant="h6" className="text-black mt-6 pb-2">
          Continuous Improvement
        </Typography>
        <Typography className="text-black leading-7 pb-5">
          We are always striving to improve our services and products to better meet the evolving needs of our customers. Our commitment to continuous improvement ensures that you receive the best possible solutions, 
          backed by the latest industry advancements and trends.
        </Typography>
      </div>

      <div className="text-justify mt-10">
  <Button
      variant="contained"
      onClick={handleClick}
      className="text-white py-2 px-4 rounded-full"
      style={{ backgroundColor: 'black', hover: { backgroundColor: '#333' } }}
    >
      Discover More
    </Button>
      </div>
    </Container>
  );
};

export default Services;
