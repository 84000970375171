import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Typography, Box, useMediaQuery, Divider } from '@mui/material';
import MenuIconButton from './MenuIconButton';
import UserIcons from './UserIcons';
import SearchBar from './SearchBar';
import Cart from '../Cart/Cart';
import { Link } from 'react-router-dom';

const Navbar = ({  onSearch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: 'white' }}>
        <Toolbar>
          {isMobile ? (
            <>
              <MenuIconButton drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
              <Typography variant="h6" sx={{ flexGrow: 1, color: '#000000', fontWeight: '900' }}>
                GBM
              </Typography>
              <UserIcons isMobile={true} toggleCart={toggleCart} />
            </>
          ) : (
            <>
              <Typography variant="h6" sx={{ flexGrow: 0, color: '#000000' }}>
                Gyasi Build Mart
              </Typography>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button component={Link} to="/" sx={{ color: '#000000' }}>
                  Home
                </Button>
                <Button component={Link} to="/services" sx={{ color: '#000000' }}>
                  Services & About
                </Button>
                <Button component={Link} to="/contact" sx={{ color: '#000000' }}>
                  Contact
                </Button>
              </Box>
              <UserIcons isMobile={false} toggleCart={toggleCart} />
            </>
          )}
        </Toolbar>
        <Toolbar>
          <SearchBar 
            handleMenuClick={handleMenuClick} 
            anchorEl={anchorEl} 
            handleMenuClose={handleMenuClose} 
            onSearch={onSearch} 
          />
        </Toolbar>
      </AppBar>

      {/* Cart Component */}
      <Cart open={cartOpen} setOpen={setCartOpen} />

      {/* Optional white line for separating the navigation */}
      {isMobile && (
        <Divider sx={{ borderColor: 'white' }} />
      )}
    </>
  );
};

export default Navbar;
