import React from "react";
import { Avatar, Card, CardContent, Typography, Button, Divider } from "@mui/material";
import { useUser } from "../../Context/UserContext"; // Adjust path as necessary
import { useNavigate } from 'react-router-dom';
import { Settings, Work, Report, ContactEmergency, Delete, Login, Logout } from "@mui/icons-material";
import { auth } from '../Auth/FirebaseConfig'; // Import auth for logout functionality

const ProfileView = () => {
  const userData = useUser();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const confirmed = window.confirm("Are you sure you want to leave GBM so soon?");
    if (confirmed) {
      try {
        await auth.signOut(); // Log out the user
        navigate('/signin'); // Redirect to the sign-in page
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
  };

  const handleDeleteAccount = async () => {
    const confirmed = window.confirm("Are you sure you want to delete your GBM account? This action cannot be undone.");
    if (confirmed && userData) {
      try {
        await auth.currentUser.delete(); // Delete the user's account
        navigate('/signup'); // Redirect to the sign-up page
      } catch (error) {
        console.error("Error deleting account:", error);
      }
    }
  };

  if (!userData) {
    return (
      <div className="container mx-auto p-6 max-w-screen-lg rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">Profile</h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Side: Profile */}
          <Card className="w-full max-w-sm mx-auto lg:mx-0 shadow-lg rounded-lg">
            <CardContent className="flex flex-col items-center text-center p-6">
              <Avatar
                alt={""}
                src={''}
                className="mb-4 w-28 h-28"
              />
              <Typography variant="h5" component="h2" className="text-xl font-semibold text-gray-800">
                Kindly Sign Up
              </Typography>
              <Typography variant="body1" className="text-gray-600">
                No email available
              </Typography>
            </CardContent>
          </Card>

          {/* Right Side: Settings and Actions */}
          <div className="space-y-6">
            <div className="space-y-4">
              <Divider />
              <div className="flex items-center space-x-3 text-gray-800">
                <Work />
                <span className="text-lg">How it Works</span>
              </div>
              <Divider />
              <div className="flex items-center space-x-3 text-gray-800">
                <ContactEmergency />
                <span className="text-lg">Contact Us</span>
              </div>
            </div>

            {/* Actions Section */}
            <div className="mt-10 space-y-2">
              <Button
                onClick={() => navigate('/signin')} // Redirect to the sign-in page
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<Login />}
                className="text-white bg-black hover:bg-gray-800"
              >
                Sign In
              </Button>
              <Button
                onClick={() => navigate('/signup')}
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<Logout />}
                className="text-black border-black hover:bg-gray-100"
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 max-w-screen-lg rounded-lg lg:shadow-lg">
      <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">Profile</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Side: Profile */}
        <Card className="w-full max-w-sm mx-auto lg:mx-0 shadow-lg rounded-lg">
          <CardContent className="flex flex-col items-center text-center p-6">
            <Avatar
              alt={userData.name}
              src={userData.photoURL}
              className="mb-4 w-28 h-28"
            />
            <Typography variant="h5" component="h2" className="text-xl font-semibold text-gray-800">
              {userData.name}
            </Typography>
            <Typography variant="body1" className="text-gray-600">
              {userData.email}
            </Typography>
          </CardContent>
        </Card>

        {/* Right Side: Settings and Actions */}
        <div className="space-y-6">
        {
          /*          <div className="space-y-4">
            <div className="flex items-center space-x-3 text-gray-800">
              <Settings />
              <span className="text-lg">Account Settings</span>
            </div>
            <Divider />
            <div className="flex items-center space-x-3 text-gray-800">
              <Work />
              <span className="text-lg">How it Works</span>
            </div>
            <Divider />
            <div className="flex items-center space-x-3 text-gray-800">
              <Report />
              <span className="text-lg">Report a Concern</span>
            </div>
            <Divider />
            <div className="flex items-center space-x-3 text-gray-800">
              <ContactEmergency />
              <span className="text-lg">Contact Us</span>
            </div>
          </div> */
        }


          {/* Actions Section */}
          <div className="mt-10 space-y-2">
            <Button
              onClick={handleLogout}
              variant="contained"
              color="error"
              fullWidth
              startIcon={<Logout />}
              className="text-white bg-red-600 hover:bg-red-700"
            >
              Logout
            </Button>
            <Button
              onClick={handleDeleteAccount}
              variant="outlined"
              color="error"
              fullWidth
              startIcon={<Delete />}
              className="text-red-600 border-red-600 hover:bg-red-50"
            >
              Delete Account
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
