import React from 'react';

const PlaceholderCard = () => (
  <div className="relative bg-gray-200 p-3 rounded-lg shadow-sm border border-gray-300 animate-pulse">
    <div className="block h-full">
      <div className="relative w-full h-48 flex items-center justify-center overflow-hidden rounded-lg bg-gray-300">
        <div className="w-full h-full bg-gray-400"></div>
      </div>
      <div className="mt-2">
        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
      </div>
      <div className="mt-2">
        <div className="h-4 bg-gray-300 rounded w-1/2"></div>
      </div>
    </div>
  </div>
);

export default PlaceholderCard;
