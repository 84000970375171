import React from 'react';
import { Link } from 'react-router-dom';
import { FaHeart, FaRegHeart } from 'react-icons/fa';

const Card = ({ product, liked, handleToggleLike }) => {
  const price = Number(product.price); // Ensure price is a number

  return (
    <div className="relative bg-white p-3 rounded-lg shadow-sm border border-gray-300 hover:shadow-md transition-shadow duration-300 ease-in-out">
      <Link to={`/details/${product.id}`} className="block h-full">
        <div className="relative w-full h-48 flex items-center justify-center overflow-hidden rounded-lg bg-gray-200">
          <div
            className={`absolute top-0 left-0 m-2 p-1 text-xs font-medium text-white rounded-full ${
              product.availability === 'inStock' ? 'bg-green-700' : 'bg-red-700'
            }`}
          >
            {product.availability === 'inStock' ? 'In stock' : 'Out of stock'}
          </div>
          <img
            alt={product.name}
            src={product.images[0]}
            className={`w-full h-full object-cover ${product.availability === 'outOfStock' ? 'opacity-50' : ''}`}
          />
        </div>
        <div className="flex items-center justify-between mt-2">
          <h3 className="text-sm font-medium text-gray-700 flex-grow">{product.name}</h3>
        </div>
        <p className="text-sm font-medium text-gray-900 mt-1">
          {price === 0 ? 'View Price Options' : `GH₵ ${price.toFixed(2)}`} {/* Use the number here */}
        </p>
      </Link>
      <button
        onClick={handleToggleLike}
        className="absolute top-2 right-2 text-gray-700 hover:text-black mt-[225px] mr-2"
        style={{
          background: 'none',
          border: 'none',
          outline: 'none',
          padding: '12px', 
          borderRadius: '50%',
        }}
        aria-label={liked ? 'Unlike' : 'Like'}
      >
        {liked ? <FaHeart size={22} color="black" /> : <FaRegHeart size={22} />}
      </button>
    </div>
  );
};

export default Card;
