import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { IoHeartOutline, IoCartOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext';
import { useFavorites } from '../../Context/FavoriteContext'; // Import useFavorites hook
import { useCart } from '../../Context/CartContext';

const UserIcons = ({ isMobile, toggleCart }) => {
  const navigate = useNavigate();
  const iconSize = isMobile ? '32px' : '30px';
  const userData = useUser();
  const { favorites } = useFavorites(); // Get the favorites from the context
  const { getDistinctItemCount } = useCart();

  const handleProfileClick = () => {
    navigate('/profileview');
  };
  
  const handleFavoriteClick = () => {
    navigate('/favorite');
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton 
        color="primary" 
        onClick={handleProfileClick} 
        aria-label="Go to profile"
      >
        {userData && userData.photoURL ? (
          <img
            src={userData.photoURL}
            alt="Profile"
            style={{ width: iconSize, height: iconSize, borderRadius: '50%' }}
          />
        ) : (
          <AccountCircle sx={{ color: '#000000', fontSize: iconSize }} />
        )}
      </IconButton>

      <Box sx={{ position: 'relative' }}>
        <IconButton 
          color="primary" 
          onClick={handleFavoriteClick} 
          aria-label="View favorite items"
        >
          <IoHeartOutline size={iconSize} color='#000000' />
        </IconButton>
        {favorites.length > 0 && ( // Only show the count if there are favorites
          <Typography
            variant="body2"
            color="#fff"
            sx={{
              position: 'absolute',
              top: 0,
              right: -5,
              backgroundColor: '#797272',
              borderRadius: '50%',
              padding: '2px 5px',
              fontSize: '0.75rem',
              minWidth: '20px',
              textAlign: 'center',
            }}
            aria-label={`${favorites.length} favorite items`}
          >
            {favorites.length}
          </Typography>
        )}
      </Box>

      <Box sx={{ position: 'relative' }}>
        <IconButton 
          color="primary" 
          onClick={toggleCart} 
          aria-label="View cart"
        >
          <IoCartOutline size={iconSize} color='#000000' />
        </IconButton>
        {/* Replace with dynamic cart count when you have that feature */}
        <Typography
          variant="body2"
          color="#fff"
          sx={{
            position: 'absolute',
            top: -0,
            right: -5,
            backgroundColor: '#797272',
            borderRadius: '50%',
            padding: '2px 5px',
            fontSize: '0.75rem',
            minWidth: '20px',
            textAlign: 'center',
          }}
          aria-label={`${getDistinctItemCount()} items in cart`}
        >
          {getDistinctItemCount()}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserIcons;
