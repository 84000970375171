import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Construction from "../../../Assets/Images/hardwareCarousel/Construction.avif";
import Hardware from "../../../Assets/Images/hardwareCarousel/Hardware.jpeg";
import Screw from "../../../Assets/Images/hardwareCarousel/Screw.webp";
import ShovelHardware from "../../../Assets/Images/hardwareCarousel/ShovelHardware.jpeg";
import WallPaper from "../../../Assets/Images/hardwareCarousel/Cement.jpg";


const HardwareCarousel = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const items = [
    {
      src: Construction,
      text: 'Comprehensive Solutions for All Your Construction Needs',
    },
    {
      src: Hardware,
      text: 'Top-Quality Hardware Tools for Every Construction Task',
    },
    {
      src: Screw,
      text: 'Reliable Screws for Secure and Long-Lasting Assembly',
    },
    {
      src: ShovelHardware,
      text: 'Durable Shovels for Efficient Construction and Landscaping',
    },
    {
      src: WallPaper,
      text: 'Quality Cements at Affordable Prices to Hold your House',
    },
  ];


  return (
    <Box
      sx={{
        position: 'relative',
        marginTop: '0', // Adjust this value to match the height of your navbar
        zIndex: 0, // Ensure it sits under any fixed elements like the navbar
      }}
    >
      <Carousel
        indicators={false}
        navButtonsAlwaysVisible={false}
        animation="slide"
        interval={5000}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              height: isMobile ? '350px' : '500px',
              backgroundImage: `url(${item.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Dark Overlay */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay with 50% opacity
                zIndex: 1,
              }}
            />

            {/* Bolded Text */}
            <Typography
              variant={isMobile ? 'h6' : 'h4'}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                position: 'relative',
                zIndex: 2,
                textAlign: 'center',
                padding: '0 10px',
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default HardwareCarousel;
