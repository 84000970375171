import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../Components/Auth/FirebaseConfig';
import { collection, doc, updateDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

// Create the CartContext
const CartContext = createContext();

// CartProvider component to provide the cart data to children components
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        const cartRef = collection(firestore, `users/${user.uid}/Cart`);

        const unsubscribeCart = onSnapshot(cartRef, (snapshot) => {
          const items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setCartItems(items);
          setLoading(false);  // Set loading to false once data is fetched
        });

        return () => unsubscribeCart();
      } else {
        setCartItems([]);  // Clear cart if no user is authenticated
        setLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const handleIncrement = async (productId) => {
    const user = auth.currentUser;
    if (user) {
      const cartItemRef = doc(firestore, `users/${user.uid}/Cart`, productId);
      const item = cartItems.find(item => item.id === productId);
      const newQuantity = item.quantity + 1;

      setCartItems(cartItems.map(item => 
        item.id === productId ? { ...item, quantity: newQuantity } : item
      ));

      await updateDoc(cartItemRef, {
        quantity: newQuantity,
        totalPrice: item.unitPrice * newQuantity,
      });
    }
  };

  const handleDecrement = async (productId) => {
    const user = auth.currentUser;
    if (user) {
      const cartItemRef = doc(firestore, `users/${user.uid}/Cart`, productId);
      const item = cartItems.find(item => item.id === productId);
      const newQuantity = Math.max(item.quantity - 1, 1);

      setCartItems(cartItems.map(item => 
        item.id === productId ? { ...item, quantity: newQuantity } : item
      ));

      await updateDoc(cartItemRef, {
        quantity: newQuantity,
        totalPrice: item.unitPrice * newQuantity,
      });
    }
  };

  const handleDelete = async (productId) => {
    const user = auth.currentUser;
    if (user) {
      const cartItemRef = doc(firestore, `users/${user.uid}/Cart`, productId);

      setCartItems(cartItems.filter(item => item.id !== productId));

      await deleteDoc(cartItemRef);
    }
  };
  
  const getDistinctItemCount = () => {
    return new Set(cartItems.map(item => item.id)).size;
  };

  return (
    <CartContext.Provider value={{ cartItems, handleIncrement, handleDecrement, handleDelete, loading,getDistinctItemCount  }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
export const useCart = () => useContext(CartContext);
