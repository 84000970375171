import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Carousel1 from "../../Assets/Images/carousel1.avif";
import Carousel2 from "../../Assets/Images/carousel2.jpg";
import Carousel3 from "../../Assets/Images/carousel3.jpg";
import Carousel4 from "../../Assets/Images/carousel4.webp";
import Carousel6 from "../../Assets/Images/carousel6.jpg";

const ImageCarousel = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const items = [
    {
      src: Carousel1,
      text: 'Your One-Stop Shop for All Things Construction',
    },
    {
      src: Carousel2,
      text: 'Reliable plumbing products for smooth, efficient flow.',
    },
    {
      src: Carousel3,
      text: 'Brighten your home with energy-saving lighting solutions from GBM.',
    },
    {
      src: Carousel4,
      text: 'GBM Transforms your kitchen with modern essentials for a stylish and efficient space.',
    },
    {
      src: Carousel6,
      text: 'Build your dream home with GBM—quality and innovation from foundation to finish.',
    },
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        marginTop: '0', // Adjust this value to match the height of your navbar
        zIndex: 0, // Ensure it sits under any fixed elements like the navbar
      }}
    >
      <Carousel
        indicators={false}
        navButtonsAlwaysVisible={false}
        animation="slide"
        interval={5000}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              height: isMobile ? '350px' : '500px',
              backgroundImage: `url(${item.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Dark Overlay */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay with 50% opacity
                zIndex: 1,
              }}
            />

            {/* Properly Ordered Headings */}
            <Typography
              variant={isMobile ? 'h6' : 'h4'}
              component={isMobile ? 'h2' : 'h1'} // Ensure the correct heading level is used
              sx={{
                color: 'white',
                fontWeight: 'bold',
                position: 'relative',
                zIndex: 2,
                textAlign: 'center',
                padding: '0 10px',
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default ImageCarousel;
