import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Screens/Home/Home";
import DetailsPage from "./Screens/Details/DetailsPage";
import SignUp from "./Components/Auth/SignUp";
import SignIn from "./Components/Auth/SignIn";

import { UserProvider } from "./Context/UserContext";
import { ProductsProvider } from "./Context/ProductContext";
import { FavoritesProvider } from "./Context/FavoriteContext";
import { CartProvider } from "./Context/CartContext";

import ProfileView from "./Components/Auth/ProfileView";
import Favorite from "./Components/Cart/Favorite";
import Services from "./Screens/General/Services";
import HardwarePage from "./Screens/Category/Hardware/HardwarePage";
import PlumbingPage from "./Screens/Category/Plumbing/PlumbingPage";
import ElectricalsPage from "./Screens/Category/Electricals/ElectricalsPage";
import ConstructionPage from "./Screens/Category/Construction/ConstructionPage";

import PipeFittingPage from "./Screens/Category/SubCategories/PipeFittingPage"
import PipePage from "./Screens/Category/SubCategories/PipePage"
import CementPage from "./Screens/Category/SubCategories/CementPage"
import RoofingPage from "./Screens/Category/SubCategories/RoofingPage";
import TilesPage from "./Screens/Category/SubCategories/TilesPage";
import PlywoodPage from "./Screens/Category/SubCategories/PlywoodPage";
import PaintPage from "./Screens/Category/SubCategories/PaintPage";
import WindowGlassPage from "./Screens/Category/SubCategories/WindowGlassPage";
import MeshPage from "./Screens/Category/SubCategories/MeshPage";
import WCPage from "./Screens/Category/SubCategories/WCPage";
import SinkPage from "./Screens/Category/SubCategories/SinkPage";
import TapsPage from "./Screens/Category/SubCategories/TapsPage";
import BasinPage from "./Screens/Category/SubCategories/BasinPage";
import IronRodPage from "./Screens/Category/SubCategories/IronRodPage";
import DoorsPage from "./Screens/Category/SubCategories/DoorsPage";
import PoPMaterialPage from "./Screens/Category/SubCategories/PoPMaterialPage";
import NailsPage from "./Screens/Category/SubCategories/NailsPage";
import WaterproofMaterialsPage from "./Screens/Category/SubCategories/WaterproofMaterialsPage";
import OthersPage from "./Screens/Category/SubCategories/OthersPage";
import TnGPage from "./Screens/Category/SubCategories/TnGPage";
import KeysPage from "./Screens/Category/SubCategories/Keys";
import HingesPage from "./Screens/Category/SubCategories/HingesPage";
import PadlockPage from "./Screens/Category/SubCategories/PadlockPage";
import BulbPage from "./Screens/Category/SubCategories/BulbPage";
import PprFittingPage from "./Screens/Category/SubCategories/PprFittingPage";
import PprPipePage from "./Screens/Category/SubCategories/PprPipePage";
import PhasePage from "./Screens/Category/SubCategories/3PhasePage";
import FloodLightPage from "./Screens/Category/SubCategories/FloodLightPage";
import HandlePage from "./Screens/Category/SubCategories/HandlePage";
import HaspAndStaplePage from "./Screens/Category/SubCategories/HaspAndStaplePage";
import JBPage from "./Screens/Category/SubCategories/JB";
import LightsPage from "./Screens/Category/SubCategories/LightsPage";
import MainSwitchPage from "./Screens/Category/SubCategories/MainSwitchPage";
import ScrewPage from "./Screens/Category/SubCategories/ScrewPage";
import FanPage from "./Screens/Category/SubCategories/FanPage";
import SandPaperPage from "./Screens/Category/SubCategories/SandPaperPage";
import TowerBoatPage from "./Screens/Category/SubCategories/TowerBoatPage";
import BathroomAccessoriesPage from "./Screens/Category/SubCategories/BathroomAccessories";
import CablePage from "./Screens/Category/SubCategories/CablePage";
import SingleDoorPage from "./Screens/Category/SubCategories/SingleDoorPage";
import PolishingLiquidPage from "./Screens/Category/SubCategories/PolishingLiquidPage";
import ChandelierPage from "./Screens/Category/SubCategories/ChandelierPage";
import PVCPipePage from "./Screens/Category/SubCategories/PVCPipePage";
import PaintingToolsPage from "./Screens/Category/SubCategories/PaintingToolsPage";

function App() {
  return (
    <UserProvider>
    <CartProvider>
    <ProductsProvider>
    <FavoritesProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/details/:id" element={<DetailsPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/profileview" element={<ProfileView />} />
          <Route path="/favorite" element={<Favorite />} />
          <Route path="/services" element={<Services />} />

          <Route path="/category/construction" element={<ConstructionPage />} />
          <Route path="/category/plumbing" element={<PlumbingPage />} />
          <Route path="/category/hardware" element={<HardwarePage />} />
          <Route path="/category/electricals" element={<ElectricalsPage />} />

          <Route path="/subcategory/pipe fitting" element={<PipeFittingPage />} />
          <Route path="/subcategory/pipes" element={<PipePage />} />
          <Route path="/subcategory/cement" element={<CementPage />} />
          <Route path="/subcategory/roofing materials" element={<RoofingPage />} />
          <Route path="/subcategory/tiles" element={<TilesPage />} />
          <Route path="/subcategory/plywood" element={<PlywoodPage />} />
          <Route path="/subcategory/paint" element={<PaintPage />} />
          <Route path="/subcategory/painting tools" element={<PaintingToolsPage />} />
          <Route path="/subcategory/window glass" element={<WindowGlassPage />} />
          <Route path="/subcategory/mesh" element={<MeshPage />} />
          <Route path="/subcategory/wc" element={<WCPage />} />
          <Route path="/subcategory/sink" element={<SinkPage />} />
          <Route path="/subcategory/taps" element={<TapsPage />} />
          <Route path="/subcategory/basin" element={<BasinPage />} />
          <Route path="/subcategory/pvc pipe" element={<PVCPipePage />} />
          <Route path="/subcategory/ironrod" element={<IronRodPage />} />
          <Route path="/subcategory/doors" element={<DoorsPage />} />
          <Route path="/subcategory/single door" element={<SingleDoorPage />} />
          <Route path="/subcategory/pop materials" element={<PoPMaterialPage />} />
          <Route path="/subcategory/nails" element={<NailsPage />} />
          <Route path="/subcategory/waterproof materials" element={<WaterproofMaterialsPage />} />
          <Route path="/subcategory/T and G" element={<TnGPage />} />
          <Route path="/subcategory/Keys" element={<KeysPage />} />
          <Route path="/subcategory/padlocks" element={<PadlockPage />} />
          <Route path="/subcategory/Bathroom Accessories" element={<BathroomAccessoriesPage />} />
          <Route path="/subcategory/Tower Boat" element={<TowerBoatPage />} />
          <Route path="/subcategory/cable" element={<CablePage />} />
          <Route path="/subcategory/hinges" element={<HingesPage />} />
          <Route path="/subcategory/Polishing Liquid" element={<PolishingLiquidPage />} />
          <Route path="/subcategory/Chandelier" element={<ChandelierPage />} />
          <Route path="/subcategory/handles" element={<HandlePage />} />
          <Route path="/subcategory/Hasp and Staple" element={<HaspAndStaplePage />} />
          <Route path="/subcategory/bulb" element={<BulbPage />} />
          <Route path="/subcategory/Fan" element={<FanPage />} />
          <Route path="/subcategory/Screw" element={<ScrewPage />} />
          <Route path="/subcategory/Sandpaper" element={<SandPaperPage />} />
          <Route path="/subcategory/JB" element={<JBPage />} />
          <Route path="/subcategory/Lights" element={<LightsPage />} />
          <Route path="/subcategory/Main Switch" element={<MainSwitchPage />} />
          <Route path="/subcategory/PPr Fitting" element={<PprFittingPage />} />
          <Route path="/subcategory/PPr Pipe" element={<PprPipePage />} />
          <Route path="/subcategory/3-phase" element={<PhasePage />} />
          <Route path="/subcategory/flood light" element={<FloodLightPage />} />
          <Route path="/subcategory/others" element={<OthersPage />} />

        </Routes>
      </Router>
      </FavoritesProvider>
      </ProductsProvider>
    </CartProvider>
    </UserProvider>
  );
}

export default App;

