import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { IconButton, Box, CircularProgress } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useProducts } from "../../Context/ProductContext"; // Import the custom hook
import { firestore, auth } from "../../Components/Auth/FirebaseConfig"; // Import Firestore and Auth
import { doc, setDoc, updateDoc, getDoc, onSnapshot } from "firebase/firestore";
import CardCarousel from "../../Components/Reusables/CardCarousel";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"; 

export default function DetailsPage() {
  const { id } = useParams();
  const { products, loading, error } = useProducts();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0); // State for current slide
  const [selectedPrice, setSelectedPrice] = useState(null); // State for selected price
  const [priceType, setPriceType] = useState(null); // State for price type
  const [addingToCart, setAddingToCart] = useState(false); // State for activity indicator
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categoryFilteredProduct, setCategoryFilteredProduct] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  // Scroll to the top when component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

// Utility function to shuffle an array
const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

// Inside your useEffect that sets filtered products
useEffect(() => {
  if (products.length) {
    const foundProduct = products.find((p) => p.id === id);
    if (foundProduct) {
      setProduct(foundProduct);

      const subCategoryProducts = products.filter(
        (p) => p.subCategory === foundProduct.subCategory
      );
      setFilteredProducts(shuffleArray(subCategoryProducts)); // Shuffled products

      const CategoryProducts = products.filter(
        (p) => p.category === foundProduct.category
      );
      setCategoryFilteredProduct(shuffleArray(CategoryProducts)); // Shuffled products

      setAllProducts(shuffleArray(products));

      // Set initial price and price type
      if (foundProduct.prices) {
        const initialPriceType = Object.keys(foundProduct.prices)[0];
        setPriceType(initialPriceType);
        setSelectedPrice(
          foundProduct.prices[initialPriceType].firstUnitPrice
        );
      } else {
        setSelectedPrice(foundProduct.price);
      }
    }
  }
}, [products, id]);



  // Ensure useEffect is called unconditionally
  useEffect(() => {
    const user = auth.currentUser;
    if (user && product?.id) {
      const cartItemRef = doc(firestore, `users/${user.uid}/Cart`, product.id);

      // Set up a real-time listener on the cart item
      const unsubscribe = onSnapshot(cartItemRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setQuantity(data.quantity);
        }
      });

      // Clean up the listener on component unmount
      return () => unsubscribe();
    }
  }, [product?.id]);

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10">Error: {error}</div>;
  if (!product)
    return <div className="text-center mt-10">Loading Product</div>;

  const handleIncrease = async () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    await updateCartItem(newQuantity);
  };

  const handleDecrease = async () => {
    const newQuantity = Math.max(quantity - 1, 1);
    setQuantity(newQuantity);
    await updateCartItem(newQuantity);
  };

  const handleThumbnailClick = (index) => setCurrentIndex(index); // Handle thumbnail click

  // Handle price type change
  const handlePriceTypeChange = (type) => {
    setPriceType(type);
    if (product.prices && product.prices[type]) {
      setSelectedPrice(product.prices[type].firstUnitPrice);
    }
  };

  // Handle price toggle
  const handlePriceToggle = (price) => {
    setSelectedPrice(price);
  };

  // Function to get price type labels
  const getPriceLabels = (type) => {
    switch (type) {
      case "TonPieces":
        return { firstLabel: "Ton", secondLabel: "Pieces" };
      case "BoxPieces":
        return { firstLabel: "Box", secondLabel: "Pieces" };
      case "BundleYards":
        return { firstLabel: "Bundle", secondLabel: "Yards" };
      case "BoxPounds":
        return { firstLabel: "Box", secondLabel: "Pounds" };
      case "PacketPieces":
        return { firstLabel: "Packet", secondLabel: "Pieces" };
      case "1-1/2Single":
        return { firstLabel: "1-1/2", secondLabel: "Single" };
      default:
        return { firstLabel: "", secondLabel: "" };
    }
  };

  const { firstLabel, secondLabel } = priceType
    ? getPriceLabels(priceType)
    : { firstLabel: "", secondLabel: "" };

  // Function to add/update product in cart
  const addToCart = async () => {
    const user = auth.currentUser;
    if (!user) {
      toast.error("You need to be logged in to add items to the cart.");
      return;
    }
  
    setAddingToCart(true);
  
    const cartItemRef = doc(firestore, `users/${user.uid}/Cart`, product.id);
    const cartItemData = {
      productId: product.id,
      name: product.name,
      unitPrice: selectedPrice,
      quantity,
      totalPrice: selectedPrice * quantity,
      imageUrl: product.images[0],
    };
  
    try {
      // Add or update item in the cart
      await setDoc(cartItemRef, cartItemData, { merge: true });
      toast.success(`${product.name} product added to cart successfully!`);
    } catch (error) {
      console.error("Error adding item to cart:", error);
      toast.error("Failed to add item to cart.");
    } finally {
      setAddingToCart(false);
    }
  };
  

  // Update the cart item quantity and total price in the database
  const updateCartItem = async (newQuantity) => {
    const user = auth.currentUser;
    if (!user) return;

    const cartItemRef = doc(firestore, `users/${user.uid}/Cart`, product.id);

    try {
      await updateDoc(cartItemRef, {
        quantity: newQuantity,
        totalPrice: selectedPrice * newQuantity,
      });
    } catch (error) {
      console.error("Error updating item quantity:", error);
    }
  };

  return (
    <Box>
    <ToastContainer />
      <div className="pt-6 mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
            {/* Static Back Arrow */}
            <div className="fixed top-4 left-4 z-50 flex items-center space-x-2 bg-gray-200 rounded-full px-4 py-2 shadow-md hover:bg-gray-300 transition-colors duration-300" onClick={() => navigate("/")}>
            <IconButton
              className="text-gray-900 hover:text-gray-700 transition-colors duration-300"
            >
              <ArrowBack />
            </IconButton>
            <span className="text-gray-900 font-semibold">Go to Home Page</span>
          </div>
        <div className="flex flex-col lg:flex-row gap-12">
          {/* Carousel */}
          <div className="relative w-full lg:w-1/2">
            <Carousel
              index={currentIndex}
              navButtonsAlwaysVisible
              navButtonsProps={{ style: { backgroundColor: "#333" } }}
              indicatorContainerProps={{ style: { display: "none" } }}
              autoPlay
              cycleNavigation
              interval={10000}
              className="h-100 lg:h-96" // Increased height for larger carousel
            >
              {product.images.map((img, idx) => (
                <div
                  className="flex items-center justify-center h-full w-full"
                  key={idx}
                >
                  <img
                    src={img}
                    alt={`Gyasi Build Mart Slide ${idx + 1}`}
                    className="object-cover w-full h-full rounded-lg"
                  />
                </div>
              ))}
            </Carousel>

            {/* Thumbnail Navigation */}
            <div className="mt-4 flex justify-center space-x-2">
              {product.images.map((img, idx) => (
                <img
                  src={img}
                  alt={`GBM Thumbnail ${idx + 1}`}
                  key={idx}
                  className={`h-16 w-16 object-cover cursor-pointer border ${
                    currentIndex === idx ? "border-black" : "border-gray-300"
                  } rounded-lg`}
                  onClick={() => handleThumbnailClick(idx)}
                />
              ))}
            </div>
          </div>

          {/* Product Details */}
          <div className="w-full lg:w-1/2 lg:pl-8 flex flex-col justify-between">
            <div>
              <h1 className="text-4xl font-extrabold text-gray-900 mb-4">
                {product.name}
              </h1>

              {/* Conditional Rendering of Price */}
              <div>
                <p className="text-2xl font-semibold text-gray-800">
                  GH₵ {selectedPrice.toFixed(2)}
                </p>
                {product.prices && (
                  <div className="mt-4">
                    <p className="text-lg font-medium text-gray-600">
                      Available in:{" "}
                      {Object.keys(product.prices)
                        .join(", ")
                        .replace(/([A-Z])/g, " $1")
                        .trim()}
                    </p>
                    {priceType &&
                      product.prices &&
                      product.prices[priceType] && (
                        <div className="mt-4">
                          <div className="flex gap-2">
                            <button
                              onClick={() =>
                                handlePriceToggle(
                                  product.prices[priceType].firstUnitPrice
                                )
                              }
                              className={`px-4 py-2 rounded-md ${
                                selectedPrice ===
                                product.prices[priceType].firstUnitPrice
                                  ? "bg-gray-900 text-white"
                                  : "bg-gray-200 text-gray-900"
                              }`}
                            >
                              {firstLabel}
                            </button>
                            <button
                              onClick={() =>
                                handlePriceToggle(
                                  product.prices[priceType].secondUnitPrice
                                )
                              }
                              className={`px-4 py-2 rounded-md ${
                                selectedPrice ===
                                product.prices[priceType].secondUnitPrice
                                  ? "bg-gray-900 text-white"
                                  : "bg-gray-200 text-gray-900"
                              }`}
                            >
                              {secondLabel}
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </div>

              {/* Removing the top margin from the description */}
              <p className="text-gray-600">{product.description}</p>
            </div>

            {/* Quantity and Add to Cart */}
            <div className="flex flex-col items-start gap-4 pt-7">
          
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-4 w-full">
            <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
            <IconButton
              size="small"
              onClick={handleDecrease}
              disabled={addingToCart}
            >
              <RemoveIcon />
            </IconButton>
            <span className="px-3 text-lg">{quantity}</span>
            <IconButton
              size="small"
              onClick={handleIncrease}
              disabled={addingToCart}
            >
              <AddIcon />
            </IconButton>
            <p className="text-lg font-bold text-gray-900">
              SubTotal: GH₵ {new Intl.NumberFormat().format((selectedPrice * quantity).toFixed(2))}
            </p>
          </div>
              <button
                onClick={addToCart}
                className={`w-full flex items-center justify-center px-6 py-3 font-semibold text-white bg-gray-900 rounded-md hover:bg-gray-700 transition-colors duration-300 ${
                  addingToCart && "opacity-50 cursor-not-allowed"
                }`}
                disabled={addingToCart}
              >
                {addingToCart ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Add to Cart"
                )}
              </button>
            </div>
          </div>
          
          
          </div>
        </div>
        <div className="pt-6">
        {/* First carousel: Similar sub-category products */}
        <h2 className="text-1xl font-bold mb-4">Similar {product.subCategory} Products</h2>
        <CardCarousel products={filteredProducts} loading={loading} />

        {/* Second carousel: Similar category products */}
        <h2 className="text-1xl font-bold mb-4 mt-4">Similar {product.category} Products</h2>
        <CardCarousel products={categoryFilteredProduct} loading={loading} />

        {/* Third carousel: All products (Our catalogue) */}
        <h2 className="text-1xl font-bold mb-4 mt-4">Browse Through Our Products Catalogue</h2>
        <CardCarousel products={allProducts} loading={loading} />
      </div>
      </div>
    </Box>
  );
}
