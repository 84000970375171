import React, { useEffect, useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useCart } from '../../Context/CartContext'; // Adjust path as needed
import CartItem from './CartItem';
import CheckoutForm from './CheckoutForm';
import OrderSummary from './OrderSummary';
import CartEmpty from './CartEmpty';
import UserEmail from './UserEmail';
import { auth, firestore } from '../Auth/FirebaseConfig'; // Firebase services
import { doc, getDoc, setDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';

export default function Cart({ open, setOpen }) {
  const { cartItems, handleIncrement, handleDecrement, handleDelete } = useCart();
  const [userEmail, setUserEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false); 
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    contact: '',
    deliveryMethod: 'pickup', // Default to pickup
    notes: '',
  });

  // Validate cartItems
  const isValidCartItems = (items) => 
    Array.isArray(items) && items.every(item =>
      typeof item.id === 'string' &&
      typeof item.name === 'string' &&
      typeof item.unitPrice === 'number' &&
      typeof item.quantity === 'number' &&
      (item.imageAlt ? typeof item.imageAlt === 'string' : true) &&
      (item.imageUrl ? typeof item.imageUrl === 'string' : true) &&
      (item.color ? typeof item.color === 'string' : true)
    );

  // Calculate subtotal
  const subtotal = cartItems.reduce((total, item) => {
    if (typeof item.unitPrice !== 'number' || typeof item.quantity !== 'number') {
      console.error('Invalid item data:', item);
      return total;
    }
    const itemTotal = item.unitPrice * item.quantity;
    return total + (isNaN(itemTotal) ? 0 : itemTotal);
  }, 0);

  // Fetch the user's email from Firestore
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const docRef = doc(firestore, 'users', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserEmail(docSnap.data().email);
          } else {
            setUserEmail(null); // Handle user not found scenario
          }
        } catch (error) {
          console.error('Error fetching user email:', error);
        }
      } else {
        setUserEmail(null); // Clear user email if not logged in
      }
    });

    return () => unsubscribe();
  }, []);

  // Handle checkout process
  const handleCheckout = async () => {
    if (!userEmail) {
      alert('User email is not available. Please log in.');
      return;
    }

    if (!isValidCartItems(cartItems)) {
      alert('Cart items have invalid data. Please review your cart.');
      return;
    }

    setLoading(true);
    const orderNumber = generateOrderNumber();
    const orderDate = new Date().toLocaleDateString();

    // Create a detailed order message for the user
    let userOrderMessage = `Thank you for shopping with us at GBM! We’re excited to confirm that we have received your order and it is under process.\n\n`;
    userOrderMessage += `Order Details:\n\n`;
    userOrderMessage += `    Order Number: ${orderNumber}\n`;
    userOrderMessage += `    Order Date: ${orderDate}\n\n`;
    userOrderMessage += `Items Purchased:\n\n`;

    cartItems.forEach(item => {
      userOrderMessage += `    ${item.name} - Quantity: ${item.quantity}, Unit Price: GHC ${item.unitPrice}\n`;
    });

    const totalAmount = cartItems.reduce((sum, item) => sum + (item.unitPrice * item.quantity), 0);
    userOrderMessage += `\nTotal Amount: GHC ${totalAmount}\n\n`;

    userOrderMessage += `Need Assistance?\n\n`;
    userOrderMessage += `If you have any questions about your order, please don’t hesitate to contact our customer service team on this email or call +233 55 121 9488. We’re here to help!\n\n`;
    userOrderMessage += `Best Regards,\nGyasi Build Mart\nwww.gyasibuildmart.store\n`;

    // Create an order message for the admin
    let adminOrderMessage = `You have received a new order from ${userEmail}.\n\n`;
    adminOrderMessage += `Order Details:\n\n`;
    adminOrderMessage += `    Order Number: ${orderNumber}\n`;
    adminOrderMessage += `    Order Date: ${orderDate}\n\n`;
    adminOrderMessage += `Items Purchased:\n\n`;

    cartItems.forEach(item => {
      adminOrderMessage += `    ${item.name} - Quantity: ${item.quantity}, Unit Price: GHC ${item.unitPrice}\n`;
    });

    adminOrderMessage += `\nTotal Amount: GHC ${totalAmount}\n\n`;
    adminOrderMessage += `Please log in to the admin portal to process this order.\n`;

    try {
      const orderRef = doc(firestore, 'Orders', orderNumber);
      const orderData = {
        userId: auth.currentUser.uid,
        orderNumber,
        orderDate,
        items: cartItems,
        totalAmount,
        ...formData // Add form data to the order
      };
      await setDoc(orderRef, orderData);

      const userOrderRef = doc(firestore, 'users', auth.currentUser.uid, 'MyOrder', orderNumber);
      await setDoc(userOrderRef, orderData);

      const userResponse = await fetch('https://gyasi-housing-server.onrender.com/send-order-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
          subject: 'Order Confirmation',
          message: userOrderMessage
        }),
      });

      const adminResponse = await fetch('https://gyasi-housing-server.onrender.com/send-order-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: 'gyasibuildmart32@gmail.com', // Admin email
          subject: `New Order Received - ${orderNumber}`,
          message: adminOrderMessage
        }),
      });

      if (userResponse.ok && adminResponse.ok) {
        alert('Order placed successfully! Order Email have been sent to your Inbox.');
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false); 
        }, 3000);

        const cartItemsSnapshot = await getDocs(collection(firestore, 'users', auth.currentUser.uid, 'Cart'));
        const deletePromises = cartItemsSnapshot.docs.map(doc => deleteDoc(doc.ref));
        await Promise.all(deletePromises);
        setOpen(false); // Close the cart dialog
      } else {
        console.error('Failed to send emails.');
        alert('Error placing order. Emails could not be sent.');
      }
    } catch (error) {
      console.error('Error processing order:', error.message);
      alert('Error processing order. Check the console for more details.');
    }
  };

  // Function to generate a unique order number
  const generateOrderNumber = () => {
    return 'ORD-' + Math.floor(Math.random() * 1000000); // Simple order number generator
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="relative">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" style={{ zIndex: 9999 }} />
      <div className="fixed inset-0 flex justify-end" style={{ zIndex: 10000 }}>
        <DialogPanel className="w-screen max-w-md bg-white shadow-xl">
          <div className="flex h-full flex-col overflow-y-scroll">
            <div className="flex-1 px-4 py-6">
              <div className="flex items-start justify-between">
                <DialogTitle className="text-lg font-medium text-gray-900">Cart</DialogTitle>
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <UserEmail userEmail={userEmail}/>
              {cartItems.length === 0 ? (
                <CartEmpty />
              ) : (
                <ul role="list" className="divide-y divide-gray-200">
                <h1 className='pt-5 text-center font-extrabold'>Cart Summary</h1>
                  {cartItems.map((item) => (
                    <CartItem
                      key={item.id}
                      item={item}
                      handleIncrement={handleIncrement}
                      handleDecrement={handleDecrement}
                      handleDelete={handleDelete}
                    />
                  ))}
                </ul>
              )}
              {cartItems.length > 0 && (
                <>
                  <OrderSummary
                    subtotal={subtotal}
                  />
                  <CheckoutForm
                    formData={formData}
                    handleChange={handleChange}
                    handleCheckout={handleCheckout}
                    loading={loading}
                    subtotal={subtotal}
                  />
                </>
              )}
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
