import React, { useState, useEffect } from 'react';
import PlumbingCarousel from './PlumbingCarousel';
import Cards from '../../../Components/Reusables/Cards';
import Navbar from '../../../Components/NavBar/NavBar';
import CategoryNavigation from "../../Home/CategoryNavigation";
import Search from "../../../Assets/Images/Search.gif";
import { useProducts } from '../../../Context/ProductContext'; // Import the custom hook

function PlumbingPage() {
  const { products, loading, error } = useProducts(); // Get products, loading, and error state from context
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (!loading && !error) {
      // Filter products to only include those in the Plumbing category
      const plumbingProducts = products.filter(product => product.category === 'Plumbing');
      setFilteredProducts(plumbingProducts);
    }
  }, [products, loading, error]); // Re-run this effect when products, loading, or error changes

  const handleSearch = (searchTerm) => {
    if (!loading && !error) {
      const searchedProducts = products.filter(product => 
        product.category === 'Plumbing' && (
          product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.description.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredProducts(searchedProducts);
    }
  };

  return (
    <>
      <Navbar onSearch={handleSearch} />
      <PlumbingCarousel />
      <CategoryNavigation excludeCategory="Plumbing" />
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>Loading...</div>
      ) : error ? (
        <div style={{ textAlign: 'center', marginTop: '20px', color: 'red' }}>{error}</div>
      ) : filteredProducts.length > 0 ? (
        <Cards products={filteredProducts} />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img 
            src={Search} 
            alt="No search found" 
            style={{ width: '150px', height: '150px', display: 'block', margin: '0 auto' }} 
          />
          <p style={{ fontSize: '14px', marginTop: '10px' }}>Oops, no item matched your search in our plumbing catalogue.</p>
        </div>
      )}
    </>
  );
}

export default PlumbingPage;
