import React from 'react';

function OrderSummary({ subtotal}) {
  return (
    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
      <p className="text-lg font-bold text-gray-900">Subtotal: GHc {new Intl.NumberFormat().format(subtotal.toFixed(2))}</p>
      
    </div>
  );
}

export default OrderSummary;
