import React, { useState, useEffect } from 'react';
import Cards from '../../../Components/Reusables/Cards';
import Navbar from '../../../Components/NavBar/NavBar';
import CategoryNavigation from '../../Home/SubCategoryNavigation';
import { useProducts } from '../../../Context/ProductContext';

const RoofingPage = () => {
  const { products, loading, error } = useProducts();
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Effect to filter products with subCategory === 'Roofing' when products change
  useEffect(() => {
    if (products.length > 0) {
      const RoofingProducts = products.filter(product => product.subCategory === 'Roofing Materials');
      setFilteredProducts(RoofingProducts);
    }
  }, [products]);

  // Function to handle search
  const handleSearch = (searchTerm) => {
    const searchedProducts = products.filter(product =>
      product.subCategory === 'Roofing' && (
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredProducts(searchedProducts);
  };

  return (
    <div style={{ width: '100%', margin: '0', padding: '0', boxSizing: 'border-box', overflowX: 'hidden' }}>
      <Navbar onSearch={handleSearch} />
      <CategoryNavigation excludeCategory="Roofing" />

      <h2 className="text-2xl font-bold tracking-tight text-gray-900 text-center mt-4">Roofing Products</h2>
      
      {loading ? (
        <Cards products={[]} loading={true} /> // Pass an empty array with loading flag
      ) : error ? (
        <div className="text-center mt-10">
          <p>Error: {error}</p>
        </div>
      ) : filteredProducts.length > 0 ? (
        <Cards products={filteredProducts} />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <p
            style={{ fontSize: '14px', marginTop: '10px' }}
          >
            Oops, no items found in our Roofing catalogue.
          </p>
        </div>
      )}
    </div>
  );
};

export default RoofingPage;
