import React from 'react';
import { Link } from 'react-router-dom';
import Empty from '../../Assets/Images/Empty.gif'; // Adjust the path if necessary

function CartEmpty() {
  return (
    <div className="flex flex-col items-center justify-center mt-16 px-4">
      <img src={Empty} alt="Empty Cart" className="h-40 w-40 mb-4" />
      <p className="text-lg font-medium text-gray-500 mb-4">Your Cart is Empty</p>
      <Link to="/category/construction">
        <button
          type="button"
          className="bg-indigo-600 text-white px-6 py-2 rounded-md shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Shop Now
        </button>
      </Link>
    </div>
  );
}

export default CartEmpty;
