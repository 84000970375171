import React, { useState, useEffect } from 'react';
import ElectricalCarousel from './ElectricalCarousel';
import Cards from '../../../Components/Reusables/Cards';
import Navbar from '../../../Components/NavBar/NavBar';
import CategoryNavigation from '../../Home/CategoryNavigation';
import Search from '../../../Assets/Images/Search.gif';
import { useProducts } from '../../../Context/ProductContext'; // Import the custom hook

function ElectricalsPage() {
  const { products, loading, error } = useProducts();
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    // Filter products based on the "Electricals" category
    if (products.length) {
      const electricalProducts = products.filter(product => product.category === 'Electricals');
      setFilteredProducts(electricalProducts);
    }
  }, [products]);

  const handleSearch = (searchTerm) => {
    const searchedProducts = products.filter(product =>
      product.category === 'Electricals' && (
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredProducts(searchedProducts);
  };

  if (loading) return <div className="text-center mt-10">Loading...</div>;
  if (error) return <div className="text-center mt-10">Error: {error}</div>;

  return (
    <>
      <Navbar onSearch={handleSearch} />
      <ElectricalCarousel />
      <CategoryNavigation excludeCategory="Electricals" />
      {filteredProducts.length > 0 ? (
        <Cards products={filteredProducts} />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img
            src={Search}
            alt="No search found"
            style={{ width: '150px', height: '150px', display: 'block', margin: '0 auto' }}
          />
          <p
            style={{ fontSize: '14px', marginTop: '10px' }}
          >
            Oops, no item matched your search in our <br />electrical catalogue.
          </p>
        </div>
      )}
    </>
  );
}

export default ElectricalsPage;
