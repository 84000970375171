import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, useMediaQuery } from '@mui/material';
import BlindCurtains from "../../../Assets/Images/electricalCarousel/BlindCurtains.jpeg";
import Chandelier from "../../../Assets/Images/electricalCarousel/Chandelier.jpeg";
import PanelLight from "../../../Assets/Images/electricalCarousel/PanelLight.jpeg";
import Switches from "../../../Assets/Images/electricalCarousel/Switches.jpeg";
import TropicalCabel from "../../../Assets/Images/electricalCarousel/TropicalCabel.jpeg";
import Walllight from "../../../Assets/Images/electricalCarousel/Walllight.jpeg";


const ConstructionCarousel = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const items = [
    {
      src: BlindCurtains,
      text: 'Elegant and Stylish Blind Curtains for Your Windows',
    },
    {
      src: Chandelier,
      text: 'Sophisticated Chandeliers to Illuminate Your Space',
    },
    {
      src: PanelLight,
      text: 'Energy-Efficient Panel Lights for Modern Interiors',
    },
    {
      src: Switches,
      text: 'Reliable and Durable Electrical Switches for Every Need',
    },
    {
      src: TropicalCabel,
      text: 'High-Quality Tropical Cables for Safe and Efficient Wiring',
    },
    {
      src: Walllight,
      text: 'Modern Wall Lights to Accentuate Your Decor',
    },
  ];
  

  return (
    <Box
      sx={{
        position: 'relative',
        marginTop: '0', // Adjust this value to match the height of your navbar
        zIndex: 0, // Ensure it sits under any fixed elements like the navbar
      }}
    >
      <Carousel
        indicators={false}
        navButtonsAlwaysVisible={false}
        animation="slide"
        interval={5000}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              height: isMobile ? '350px' : '500px',
              backgroundImage: `url(${item.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Dark Overlay */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay with 50% opacity
                zIndex: 1,
              }}
            />

            {/* Bolded Text */}
            <Typography
              variant={isMobile ? 'h6' : 'h4'}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                position: 'relative',
                zIndex: 2,
                textAlign: 'center',
                padding: '0 10px',
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default ConstructionCarousel;
