import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions"; // Import Functions

const firebaseConfig = {
  apiKey: "AIzaSyDA0msJwf37IAWWtIYHcOZvrz0cRJtIaAM",
  authDomain: "gyasi-build-smart.firebaseapp.com",
  projectId: "gyasi-build-smart",
  storageBucket: "gyasi-build-smart.appspot.com",
  messagingSenderId: "197478747144",
  appId: "1:197478747144:web:9a21ad82851b00b2ed55bd",
  measurementId: "G-FBJZ8SLK72"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app); // Initialize Functions

// Export services
export { auth, firestore, functions, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, httpsCallable };
