import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth,firestore } from '../Auth/FirebaseConfig';
import {setDoc, doc} from 'firebase/firestore'


export default function SignIn() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setIsLoading(true);

    try {
      const result =await signInWithPopup(auth, provider);
      const user = result.user
      alert('You have successfully signed in.');

      await setDoc(doc(firestore, 'users', user.uid), {
        name: user.displayName,
        email: user.email,
        contact: '', // Google Sign-In does not provide phone numbers
        photoURL: user.photoURL,
      });

      // Navigate to the home screen
      navigate('/'); // Use the path for your home screen
    } catch (error) {
      setError('Error signing in with Google. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
          GYASI BUILD MART
        </h2>
        <h2 className="mt-3 text-center text-1xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
        {error && (
          <div className="text-red-500 text-sm">
            {error}
          </div>
        )}

        <div className="mt-6">
          <button
            onClick={handleGoogleSignIn}
            disabled={isLoading}
            className="flex w-full items-center justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            <FaGoogle className="mr-2" /> {isLoading ? 'Signing in...' : 'Sign In with Google'}
          </button>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Don't have an account? { }
          <a href="/signup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
}
