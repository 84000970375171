import React, { useState } from "react";
import { TextField, InputAdornment, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Typewriter from "react-typewriter-effect";

const SearchBar = ({ onSearch }) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setInputValue(searchTerm);
    onSearch(searchTerm); // Trigger the search function with the current search term
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <TextField
        variant="outlined"
        size="small"
        placeholder="" // Transparent placeholder
        value={inputValue}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#000000" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          width: "100%",
          backgroundColor: "#f0f0f0",
          borderRadius: 2,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#cccccc",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#aaaaaa",
          },
          "& .MuiInputBase-input": {
            color: "#000000",
            padding: "10px 14px",
          },
        }}
      />
      {!inputFocused && !inputValue && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            pointerEvents: "none",
            paddingLeft: "42px",
          }}
          aria-live="polite"
        >
          <Typewriter
            textStyle={{ fontSize: 14, color: "#666666" }}
            cursorColor="#666666"
            multiText={[
              "Search through our catalog",
              "Discover premium hardware",
              "Build your house with us",
            ]}
            multiTextDelay={4000}
            typeSpeed={100}
            eraseSpeed={50}
            loop={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;
