// Define and export subcategories and their images
export const subcategories = [
    'Tiles', 'Cement', 'Roofing Materials', 'Plywood', 'Paint', 'Window Glass',
    'Mesh', 'Pipes', 'Pipe Fitting', 'WC', 'Sink', 'Taps', 'Basin', 'Iron Rod', 'Doors', 'PPr Pipe', 'PPr Fitting',
    'T and G', 'Nails', 'PoP Materials', 'Keys', 'Padlocks', 'Handles',
    'Painting Tools', 'Sandpaper', 'Polishing Liquid', 'Waterproof Materials',"Chandelier",
    'Fan', 'Cable', 'PVC Pipe', 'Lights', 'Flood Light', 'JB','Single Door',
    'Main Switch', '3-Phase', 'Bulb', 'Hinges', 'Tower Boat', 'Hasp and Staple', 'Screw','Bathroom Accessories','Others'
  ];
  
  export const subcategoryImages = {
    'Tiles': 'https://img.freepik.com/free-vector/colored-realistic-ceramic-floor-tiles-horizontal-set-with-squares-different-types-styles-tiles_1284-29013.jpg?ga=GA1.2.307588626.1711624851&semt=ais_hybrid',
    'Cement': 'https://img.freepik.com/premium-photo/utilize-trowel-apply-cement-cement-mortar-cement-bag-construction-purposes_854723-50902.jpg?ga=GA1.2.307588626.1711624851&semt=ais_hybrid',
    'Roofing Materials': 'https://img.freepik.com/free-vector/cartoon-style-roof-top-design_52683-80396.jpg?ga=GA1.2.307588626.1711624851&semt=ais_hybrid',
    'Plywood': 'https://img.freepik.com/free-photo/creative-assortment-with-different-books_23-2148851018.jpg?ga=GA1.2.307588626.1711624851&semt=ais_hybrid',
    'Paint': 'https://img.freepik.com/premium-photo/vibrant-colorful-paint-splatters-white-background-creative-artworks-designs_994764-159847.jpg?ga=GA1.2.307588626.1711624851&semt=ais_hybrid',
    'Window Glass': 'https://img.freepik.com/free-photo/low-angle-view-skyscraper_1252-1147.jpg?ga=GA1.2.307588626.1711624851&semt=ais_hybrid',
    'Mesh': 'https://img.freepik.com/free-photo/close-up-metal-grid-seamless-pattern_23-2148139851.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'Pipes': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1725347884626_IMG_5796.jpeg?alt=media&token=95d1af28-1afe-42f7-adba-fb78ccb88451',
    'Pipe Fitting': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1725354390815_IMG_5804.jpeg?alt=media&token=23504d84-c566-48d2-b3ce-052037675e3d',
    'WC': 'https://img.freepik.com/free-photo/toilet-seat_1203-3742.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'Sink': 'https://img.freepik.com/free-photo/kitchen-sink_1203-27.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'Taps': 'https://img.freepik.com/free-photo/still-life-beer-brewing-elements_23-2150321498.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'Basin': 'https://img.freepik.com/free-photo/white-sink-faucet_74190-7254.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'Chandelier': 'https://diamondenergygh.com/wp-content/uploads/2022/02/glass-new-design-LED-chandelier-for-living-room-common-space-diamond-energy-ghana-bulb-light.jpeg',
    'Iron Rod': 'https://img.freepik.com/free-photo/construction-steel-reinforcement-closeup-selective-focus-warehouse-building-materials-repair-construction-buildings-houses_166373-3679.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'Doors': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727717046102_IMG_5632.jpeg?alt=media&token=ebe193f4-9881-4d94-ba00-047e33c9dc0e',
    'Single Door': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727788839641_IMG_5770.jpeg?alt=media&token=967a673f-86fd-491a-8c79-3c3184092174',
    'PPr Pipe': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726680514262_IMG_4948.webp?alt=media&token=45a6c519-4290-4d81-81bd-6b289b70a465',
    'PPr Fitting': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726733079211_IMG_5001.webp?alt=media&token=13c9bf88-6e52-415b-84ef-e4559bafaee5',
    'T and G': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726659646154_5D120B66-7164-450E-8AD3-D0B119152F24.jpeg?alt=media&token=f22dc077-53a1-4505-846f-fc0b8d6ec66c',
    'Nails': 'https://img.freepik.com/free-photo/closeup-shot-metal-nails-white-surface_181624-19481.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'PoP Materials': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1725978327660_IMG_4378.jpeg?alt=media&token=163a69e8-7ddd-402a-bf3c-3163b9f85097',
    'Keys': 'https://img.freepik.com/premium-photo/isolated-metal-keys-plain-white-background_1287633-1148.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'Padlocks': 'https://img.freepik.com/free-vector/closed-padlocks-with-security-codes-realistic-icons-set-isolated-vector-illustration_1284-82750.jpg?ga=GA1.1.307588626.1711624851&semt=ais_hybrid',
    'Handles': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726836068782_IMG_5100.jpeg?alt=media&token=5a9f2c2a-d133-4be0-b90d-1aa45fc2159b',
    'Painting Tools': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727693864050_IMG_5585.jpeg?alt=media&token=030e3374-ba3c-4b67-af3c-f782ad194623',
    'Sandpaper': 'https://m.media-amazon.com/images/I/719pN47NtzL._AC_SL1200_.jpg',
    'Polishing Liquid': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727773329837_IMG_5740.jpeg?alt=media&token=f935a90a-28c2-41e4-a8c5-a5a783e12595',
    'Waterproof Materials': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726475995764_IMG_4679.webp?alt=media&token=5ccf63b7-0567-4d44-a177-05e1e7fa2025',
    'Fan': 'https://encrypted-tbn1.gstatic.com/shopping?q=tbn:ANd9GcRaTiUFp_XhxuhrXrYUEFaB0FDlc3thQyIB_qJuhjgxBPOgDUXg9ZHrcfXvWopkbnEv5s-tF--POXHWwmNAOmd4-8Dwh7AwcNxsD_IqknDbT8GEeCCQUYya5R80FhgQwVExzba1Wo0&usqp=CAc',
    'Cable': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727339517819_IMG_5486.jpeg?alt=media&token=0e5ae707-7900-4608-ba5a-2ec0815c457c',
    'PVC Pipe': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727341227751_IMG_5497.webp?alt=media&token=847589c2-f6f1-4f72-a400-27895b2b76ff',
    'Bathroom Accessories': 'https://supplymaster.store/cdn/shop/files/nate-bathroom-bathroom-accessories-6-in-1-pieces-set-bathroom-accessories-32930468364422.webp?v=1722798553&width=550',
    'Lights': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726941166122_IMG_5167.jpeg?alt=media&token=4fa0dc80-d0a1-4259-8a67-5c32ff2bc642',
    'Flood Light': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726855999286_IMG_5156.jpeg?alt=media&token=04f7f692-26aa-4100-836f-4d959a372442',
    'JB': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727085053338_IMG_5262.webp?alt=media&token=179a4dfd-d334-456f-b05d-4feb690ea7eb',
    'Main Switch': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727086066374_IMG_5263.webp?alt=media&token=14ae04e3-e710-45e6-8121-d2c9f054451e',
    '3-Phase': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1727086125941_IMG_5263.webp?alt=media&token=9fb840b0-1c48-4363-bf93-b0781a6a3a96',
    'Bulb': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVF-XU8rOW3CMg2A5D4GUoP9a-IgNf31txfA&s',
    'Hinges': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726751720967_IMG_5027.jpeg?alt=media&token=c14b4502-537f-45e2-8643-df5c72d777b0',
    'Tower Boat': 'https://firebasestorage.googleapis.com/v0/b/gyasi-build-smart.appspot.com/o/products%2F1726839012797_IMG_5114.jpeg?alt=media&token=c6c03ccb-945a-43c5-9c77-408d6478bf81',
    'Hasp and Staple': 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQbJk8U9Cfab6oWTQUvU80b3rc1TOkH2pWVts9EQ2HTo5U8bZNz',
    'Screw': 'https://magnetstore.co.uk/wp-content/uploads/2020/10/4mm-x-50mm-1.jpg',
    'Others': 'https://thumbs.dreamstime.com/b/construction-tools-25047831.jpg',
  };
  