import React from 'react';
import CardList from './CardList';

const Cards = ({ products, loading }) => {
  return (
    <div className="bg-white">
      <CardList products={products} loading={loading} />
    </div>
  );
};

export default Cards;
