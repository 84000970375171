import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Construction from "../../../Assets/Images/constructionCarousel/Construction.jpg";
import Mesh from "../../../Assets/Images/constructionCarousel/Mesh.jpg";
import Nails from "../../../Assets/Images/constructionCarousel/Nails.jpg";
import Painting from "../../../Assets/Images/constructionCarousel/Painting.webp";
import Tiles from "../../../Assets/Images/constructionCarousel/Tiles.avif";


const ConstructionCarousel = () => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const items = [
    {
      src: Construction,
      text: 'Comprehensive Solutions for All Your Construction Needs',
    },
    {
      src: Mesh,
      text: 'High-Quality Mesh for Reinforcement and Structural Integrity',
    },
    {
      src: Nails,
      text: 'Durable Nails for a Secure and Long-Lasting Build',
    },
    {
      src: Painting,
      text: 'Expert Painting Services for a Flawless Finish',
    },
    {
      src: Tiles,
      text: 'Premium Tiles for Elegant and Durable Flooring',
    },
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        marginTop: '0', // Adjust this value to match the height of your navbar
        zIndex: 0, // Ensure it sits under any fixed elements like the navbar
      }}
    >
      <Carousel
        indicators={false}
        navButtonsAlwaysVisible={false}
        animation="slide"
        interval={5000}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              height: isMobile ? '350px' : '500px',
              backgroundImage: `url(${item.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* Dark Overlay */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay with 50% opacity
                zIndex: 1,
              }}
            />

            {/* Bolded Text */}
            <Typography
              variant={isMobile ? 'h6' : 'h4'}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                position: 'relative',
                zIndex: 2,
                textAlign: 'center',
                padding: '0 10px',
              }}
            >
              {item.text}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default ConstructionCarousel;
